import {Done, CheckCircle, Cancel, RemoveCircle} from '@mui/icons-material'
import {Box, Chip, Stack, Typography, Button} from '@mui/material'
import {DataGrid, GridColDef} from '@mui/x-data-grid'
import React, {useEffect, useState} from 'react'
import axiosInstance from '../../../../utils/axios'
import CustomNoRowsOverlay from '../../../common/components/TableNoDataOverlay'
import {useMission} from '../core/MissionProvider'
import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {ParticipantModal} from './ParticipantsModal'
import { TMission } from '../../../../types/types'
import axios from 'axios'
import MySwal from '../../../../utils/sweetAlert'
import { TMissionRegistrations } from '../../../../types/types'
import { useAuth } from '../../auth'

type TParticipantsTableProps = {
  missionId: string
  mission: TMission
  hasPermission: boolean
  registrationStatus: boolean
}



const ParticipantsTable: React.FC<TParticipantsTableProps> = ({missionId, mission,hasPermission,registrationStatus}) => {
  const [missionParticipants, setMissionParticipants] = useState<TMissionRegistrations[]>([])
  const [statusFilter, setStatusFilter] = useState('all');
  const [hasCsr, setHasCsr] = useState()
  const [selectedRowId, setSelectedRowId] = useState(null)
  const [users, setUsers] = useState({});
  const {setLoadingScreen,setSelectedMission} = useMission()
  const navigate = useNavigate()
  const {currentUser} = useAuth()

  const SortingChips = () => {
    const handleClickComplete = () => {
      setStatusFilter('complete');
    };
  
    const handleClickIncomplete = () => {
      setStatusFilter('incomplete'); 
    };
  
    const handleClick = () => {
      setStatusFilter('all'); 
    };
  
    return (
      <Stack direction='row' spacing={2}>
        <Chip size='small' label='All' onClick={handleClick} icon={<Done />} />
        <Chip
          color='success'
          size='small'
          label='Complete'
          onClick={handleClickComplete}
          icon={<CheckCircle />}
        />
        <Chip
          color='warning'
          size='small'
          label='Incomplete'
          onClick={handleClickIncomplete}
          icon={<RemoveCircle />}
        />
      </Stack>
    )
  }


  const submitRegistration = (id: string) => {
    // axiosInstance.put(`/api/mission-registrations/${id}`,
    //  {data:{isSubmitted: true,isCancelled: false}})
    MySwal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Successfully Saved Registration Notes',
      timer: 2000,
    })
    setSelectedRowId(null) 
  }


  const editRegistrationByStep = (id: string, step: number) => {
    navigate(`/missions/view/${missionId}/registration/${id}?step=${step - 1}`)
  }
  const cancelRegistration = (id: string, compDisplayName: string) => {
    axiosInstance.put(`/api/mission-registrations/${id}`, 
    {data:{
      isSubmitted: false,
      isCancelled: true
    }})
    axiosInstance.post(`/api/registration-logs`, {
      data:{
        title: 'Cancelled',
        message: 'Cancelled their registration',
        company: compDisplayName,
        category: 'Billing Info',
        user_id: currentUser?.id,
        mission: missionId,
      }
    })
    MySwal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Registration cancelled',
      timer: 2000,
    })
    setSelectedRowId(null) 
  }

  const handleCloseModal = () => {
    setSelectedRowId(null) 
    fetchData()
  }

  const rows = []; // Your rows data here

  useEffect(() => {
    axiosInstance.get('/api/users').then(response => {
      const usersById = response.data.reduce((acc, user) => ({
        ...acc,
        [user.id]: user,
      }), {});
      setUsers(usersById);
    }).catch(error => {
      console.error("Failed to fetch users:", error);
    });
  }, []);

  useEffect(() => {
    const fetchCsr = async () => {
    const res= await axiosInstance.get(`/api/missions/${missionId}`)
    const data = res.data.data.attributes.hasCsr
    setHasCsr(data)
    }
    fetchCsr()
  },[missionId])
  const fetchData = async () => {
    setLoadingScreen(true)
    try {
      const response = await axiosInstance.get(
        `/api/mission-registrations?filters[missionId][id]=${missionId}&populate=*,missionMemparticipantsRegistrationId.photo,missionMemparticipantsRegistrationId.usersPermissionsUserId,memMissionTalkingPoints.missionMeetingsId,compLogo&pagination[page]=1&pagination[pageSize]=100`
      )
      const missionRegistrations = response.data.data
      setMissionParticipants(
        missionRegistrations.map((registrations) => ({
          ...registrations.attributes,
          id: registrations.id,
          missionMemparticipantsRegistrationId:
            registrations.attributes.missionMemparticipantsRegistrationId.data.map(
              (participant) => ({
                ...participant.attributes,
                id: participant.id,
                photo: participant.attributes.photo.data
                  ? participant.attributes.photo.data.attributes.url
                  : null,
                usersPermissionsUserId: participant.attributes.usersPermissionsUserId.data ? participant.attributes.usersPermissionsUserId.data.attributes : null,
              })
            ),
          memMissionTalkingPoints: registrations.attributes.memMissionTalkingPoints.data.map(
            (talkingPoints) => ({
              ...talkingPoints.attributes,
              id: talkingPoints.id,
              missionMeetingsId: {
                ...talkingPoints.attributes.missionMeetingsId.data.attributes,
                id: talkingPoints.attributes.missionMeetingsId.data.id,
              },
            })
          ),
          compLogo: registrations.attributes.compLogo.data
            ? registrations.attributes.compLogo.data.attributes.url
            : null,
        }))
      )
    } catch (error) {
      console.error('Error fetching mission data:', error)
    } finally {
      setLoadingScreen(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [missionId, setLoadingScreen])

  // const [rows, setRows] = useState(missionRegistrations as readonly any[])

  // const rows = missionRegistrations as readonly any[]
  const columns: GridColDef[] = [
    {
      field: 'billingRepCompany',
      headerName: 'Member',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      flex: 2,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() => {
                setSelectedRowId(params.row.id)
                setSelectedMission(mission)
              }}
            >
              {params.value}
            </Button>
            <ParticipantModal
              show={selectedRowId == params.row.id}
              handleClose={handleCloseModal}
              cancelRegistration={cancelRegistration}
              submitRegistration={submitRegistration}
              editRegistrationByStep={editRegistrationByStep}
              participant={params.row}
              isSummary={false}
              id={missionId}
              regId={params.row.id}
              viewReprenstativeMission={()=>{}}
              isRepresentativeView={false}
              hasPermission={hasPermission}
              registrationStatus={registrationStatus}
            />
          </>
        )
      },
    },
    {
      field: 'billingStatusEx',
      headerName: 'Billing',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        let status = parseInt(params.value)
        switch (status) {
          case 0:
            return (
              <>
                <KTIcon className='fs-3 text-danger' iconName='cross-circle' />
              </>
            )
          case 1:
            return (
              <>
                <KTIcon className='fs-3 text-warning' iconName='minus-circle' />
              </>
            )
          case 2:
            return (
              <>
                <KTIcon className='fs-3 text-success' iconName='check-circle' />
              </>
            )
          default:
            break
        }
      },
    },
    {
      field: 'participantStatus',
      headerName: 'Participants',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        let status = parseInt(params.value)
        switch (status) {
          case 0:
            return (
              <>
                <KTIcon className='fs-3 text-danger' iconName='cross-circle' />
              </>
            )
          case 1:
            return (
              <>
                <KTIcon className='fs-3 text-warning' iconName='minus-circle' />
              </>
            )
          case 2:
            return (
              <>
                <KTIcon className='fs-3 text-success' iconName='check-circle' />
              </>
            )
          default:
            break
        }
      },
    },
    {
      field: 'talkpointsStatus',
      headerName: 'Talking Points',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        let status = parseInt(params.value)
        switch (status) {
          case 0:
            return (
              <>
                <KTIcon className='fs-3 text-danger' iconName='cross-circle' />
              </>
            )
          case 1:
            return (
              <>
                <KTIcon className='fs-3 text-warning' iconName='minus-circle' />
              </>
            )
          case 2:
            return (
              <>
                <KTIcon className='fs-3 text-success' iconName='check-circle' />
              </>
            )
          default:
            break
        }
      },
    },
    {
      field: 'companyProfileStatus',
      headerName: 'Company Profile',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        let status = parseInt(params.value)
        switch (status) {
          case 0:
            return (
              <>
                <KTIcon className='fs-3 text-danger' iconName='cross-circle' />
              </>
            )
          case 1:
            return (
              <>
                <KTIcon className='fs-3 text-warning' iconName='minus-circle' />
              </>
            )
          case 2:
            return (
              <>
                <KTIcon className='fs-3 text-success' iconName='check-circle' />
              </>
            )
          default:
            break
        }
      },
    },

    {
      field: 'submittedCSRStatus',
      headerName: 'CSR Status',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        let status = parseInt(params.value)
        switch (status) {
          case 0:
            return (
              <>
                <KTIcon className='fs-3 text-danger' iconName='cross-circle' />
              </>
            )
          case 1:
            return (
              <>
                <KTIcon className='fs-3 text-warning' iconName='minus-circle' />
              </>
            )
          case 2:
            return (
              <>
                <KTIcon className='fs-3 text-success' iconName='check-circle' />
              </>
            )
          default:
            break
        }
      },
    },
    {
      field: 'overAllStatus',
      headerName: 'Overall Status',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if(hasCsr){
          if (params.row.isSubmitted) {
            return <span>Submitted</span>
          } else if (params.row.isCancelled) {
            return <span>Cancelled</span>
          } else if (params.row.billingStatusEx === 2 && params.row.participantStatus === 2 && params.row.talkpointsStatus === 2 && params.row.companyProfileStatus === 2 && params.row.submittedCSRStatus === 2) {
            return <span>Complete</span>
          }  else if (
            params.row.billingStatusEx === 0 ||
            params.row.participantStatus === 0 ||
            params.row.talkpointsStatus === 0 ||
            params.row.companyProfileStatus === 0 ||
            params.row.submittedCSRStatus === 0 ||
            params.row.billingStatusEx === 1 ||
            params.row.participantStatus === 1 ||
            params.row.talkpointsStatus === 1 ||
            params.row.companyProfileStatus === 1 ||
            params.row.submittedCSRStatus === 1 
          ) {
            return <span>Incomplete</span>
          }else {
            return <span>Not Yet Submitted</span>
          }
        }
        else{
          if (params.row.isSubmitted) {
            return <span>Submitted</span>
          } else if (params.row.isCancelled) {
            return <span>Cancelled</span>
          } else if (params.row.billingStatusEx === 2 && params.row.participantStatus === 2 && params.row.talkpointsStatus === 2 && params.row.companyProfileStatus === 2) {
            return <span>Complete</span>
          }  else if (
            params.row.billingStatusEx === 0 ||
            params.row.participantStatus === 0 ||
            params.row.talkpointsStatus === 0 ||
            params.row.companyProfileStatus === 0 ||
            params.row.billingStatusEx === 1 ||
            params.row.participantStatus === 1 ||
            params.row.talkpointsStatus === 1 ||
            params.row.companyProfileStatus === 1
          ) {
            return <span>Incomplete</span>
          }else {
            return <span>Not Yet Submitted</span>
          }
        }
      },
    },
    {
      field: 'createdByUser',
      headerName: 'Registered By',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        const displayName = users[params.value]?.username || "Not Specified";
        return (
          <Typography>{displayName}</Typography>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Registered Date',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        const date = new Date(params.value);
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        }).format(date);
      
        return (
          <>
            <Typography>
              {formattedDate}
            </Typography>
          </>
        )
      },
    }
  ]
  const filteredColumns = hasCsr
  ? columns
  : columns.filter((col) => col.field !== 'submittedCSRStatus');


  
  const filteredRows = missionParticipants.filter(data => !data.isCancelled)
  .filter(data => {
    if (hasCsr) {
      switch (statusFilter) {
        case 'all':
          return true;
        case 'complete':
          return data.billingStatusEx === 2 && data.participantStatus === 2 && 
                 data.talkpointsStatus === 2 && data.companyProfileStatus === 2 && 
                 data.submittedCSRStatus === 2;
        case 'incomplete':
          return data.billingStatusEx === 0 || data.participantStatus === 0 || 
                 data.talkpointsStatus === 0 || data.companyProfileStatus === 0 || 
                 data.submittedCSRStatus === 0 || data.billingStatusEx === 1 || 
                 data.participantStatus === 1 || data.talkpointsStatus === 1 || 
                 data.companyProfileStatus === 1 || data.submittedCSRStatus === 1;
        default:
          return true;
      }
    } else {
      switch (statusFilter) {
        case 'all':
          return true;
        case 'complete':
          return data.billingStatusEx === 2 && data.participantStatus === 2 && 
                 data.talkpointsStatus === 2 && data.companyProfileStatus === 2;
        case 'incomplete':
          return data.billingStatusEx === 0 || data.participantStatus === 0 || 
                 data.talkpointsStatus === 0 || data.companyProfileStatus === 0 || 
                 data.billingStatusEx === 1 || data.participantStatus === 1 || 
                 data.talkpointsStatus === 1 || data.companyProfileStatus === 1;
        default:
          return true;
      }
    }
  });


 
  return (
    <Box
      sx={{
        height: '300px',
        width: '100%',
        marginTop: '5px',
      }}
    >
      <div className='d-flex  justify-content-between mb-4'>
        <Typography variant='h5'>Mission Registrations</Typography>
        <SortingChips />
      </div>
      <DataGrid
        sx={{
          fontSize: '12px',
          boxShadow: 2,
          height: '100%',
        }}
        rows={filteredRows as unknown as readonly any[]}
        columns={filteredColumns}
        hideFooterPagination
        hideFooter
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'billingRepCompany',
                sort: 'asc',
              },
            ],
          },
        }}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        
      />
    </Box>
  )
}

export default ParticipantsTable
