import {Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {TMissionRegistrations} from '../../../../types/types'
import BillingInformationCard from './ModalComponents/BillingInformationCard'
import ParticipantInfoCard from './ModalComponents/ParticipantInfoCard'
import MeetingTalkingPointsCard from './ModalComponents/MeetingTalkingPointsCard'
import CompanyProfileCard from './ModalComponents/CompanyProfileCard'
import ActionsCard from './ModalComponents/ActionsCard'
import {useNavigate, useParams} from 'react-router-dom'
import {Button} from '@mui/material'
import axiosInstance from '../../../../utils/axios'
import MySwal from '../../../../utils/sweetAlert'
import { useEffect, useState } from 'react'
import {useMission} from '../core/MissionProvider'
import { useAuth } from '../../auth'


type Props = {
  show: boolean
  handleClose: () => void
  submitRegistration: (id: string) => void
  cancelRegistration: (id: string, compDisplayName: string) => void
  editRegistrationByStep: (id: string, step: number) => void
  participant: TMissionRegistrations
  isSummary: boolean
  id: string
  regId: string | undefined
  viewReprenstativeMission: (missionId: string,missionRegisrationId) => void
  isRepresentativeView: boolean
  hasPermission?: boolean
  registrationStatus?: boolean
}

const modalsRoot = document.getElementById('root-modals') || document.body
const ParticipantModal = ({
  show,
  handleClose,
  participant,
  submitRegistration,
  cancelRegistration,
  editRegistrationByStep,
  isSummary,
  id,
  regId,
  viewReprenstativeMission,
  isRepresentativeView,
  hasPermission,
  registrationStatus

}: Props) => {
  const triggerCancel = () => {
    cancelRegistration(participant.id,participant.compDisplayName)
  }
  const triggerSubmit = () => {
    submitRegistration(participant.id)
  }
  const triggerEdit = (step: number) => {
    editRegistrationByStep(participant.id, step)
  }
  const {missionRegisrationId,missionId}= useParams()
  const navigate = useNavigate()
  const {setLoadingScreen} = useMission()
  const [notes, setNotes] = useState<string>('')
  const {currentUser} = useAuth()
  useEffect(() => {
    if (regId) {
      try {
        axiosInstance.get(`/api/mission-registrations/${regId}`).then((response) => {
          const fetchedNotes = response.data.data.attributes.notes
          setNotes(fetchedNotes)
        })
      } catch (error) {
        setNotes('')
      }
    }
  }, [regId])
  const handleCancel = () => {
    MySwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, cancel it!',
      cancelButtonText: 'No, keep it'
    }).then(async(result) => {
      if (result.isConfirmed) {
        setLoadingScreen(true)
        await axiosInstance.put(`/api/mission-registrations/${participant.id}?populate=missionMemparticipantsRegistrationId,missionId`,
        {data:{
          isCancelled: true,
        }}).then((response) => {
          if (response.data.data.attributes.missionMemparticipantsRegistrationId.data && Array.isArray(response.data.data.attributes.missionMemparticipantsRegistrationId.data)) {
              response.data.data.attributes.missionMemparticipantsRegistrationId.data.forEach(participantRegistration => {
                  axiosInstance.put(`/api/mission-memparticipants/${participantRegistration.id}?populate=registrationId`, {
                      data: {
                          mission: null
                      }
                  }).then((updateResponse) => {
                      console.log('Updated missionMemparticipants', updateResponse.data);
                  }).catch((error) => {
                      console.error('Error updating missionMemparticipants', error);
                  });
              });
          }
      }).catch((error) => {
          console.error('Error in mission-registrations update', error);
      });
      await axiosInstance.post(`/api/registration-logs`, {
        data:{
          title: 'Cancelled',
          message: 'Cancelled their registration',
          company: participant.compDisplayName,
          category: 'Billing Info',
          user_id: currentUser?.id,
          mission: id,
        }
      })
        MySwal.fire(
          'Cancelled!',
          'Your registration has been cancelled.',
          'success'
        )
        await handleClose()
        await setLoadingScreen(false)
        await navigate(`/dashboard`)
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
       
      }
    })
  }
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
          <div className='row'>
          <div className='col-md-12 mt-3'>
            <h2>{participant.compDisplayName}</h2>
            <p>
              <strong> Participants:</strong> {participant.missionMemparticipantsRegistrationId ? participant.missionMemparticipantsRegistrationId.length : 0}
            </p>
          </div>
          </div>
            {hasPermission && (
              <>
                {isRepresentativeView ? (
                  <Button variant="contained" onClick={() => viewReprenstativeMission(id, regId ? regId : '')}>
                    Continue Registration
                  </Button>
                ) : (
                  (registrationStatus &&
                    (<>
                  <Button variant="contained" className='btn btn-danger' onClick={handleCancel} style={{marginLeft:'200px'}}>
                    Cancel Registration
                  </Button>
                    <Button variant="contained" className='btn btn-primary' onClick={() => navigate(`/missions/view/${missionId}/registration/${regId ? regId : missionRegisrationId}`)}>
                    Continue Registration
                  </Button>
                  </>
                    )
                  )   
                )}
              </>
            )}
            {!hasPermission && registrationStatus ? (
              <Button variant="contained" onClick={() => viewReprenstativeMission(id, regId ? regId : '')}>
                Continue Registration
              </Button>
            ) : (
              <></>
            )}
      </div>
      <div className='modal-body'>
        <div className='row px-3 mb-5'>
          <BillingInformationCard
            billingInformation={{
              billingAddress: participant.billingAddress,
              billingEmailAdd: participant.billingEmailAdd,
              billingInfoId: participant.billingInfoId,
              billingPhoneNum: participant.billingPhoneNum,
              billingRepCompany: participant.billingRepCompany,
              billingRepName: participant.billingRepName,
              billingRepTitle: participant.billingRepTitle,
              billingSpecIns: participant.billingSpecIns,
              billingStatus: participant.billingStatus,
              billingStatusEx: participant.billingStatusEx,
            }}
            triggerEdit={triggerEdit}
            isSummary={isSummary}
            hasPermission={hasPermission}
          />
        </div>

        {participant.missionMemparticipantsRegistrationId &&
          participant.missionMemparticipantsRegistrationId.map((participant, index) => (
            <div className='row px-3 mb-5' key={index}>
              <ParticipantInfoCard participantInfo={participant} triggerEdit={triggerEdit} isSummary={isSummary} hasPermission={hasPermission}/>
            </div>
          ))}

        {participant.memMissionTalkingPoints && participant.memMissionTalkingPoints.length > 0 ? (
          <div className='row px-3 mb-5'>
            <MeetingTalkingPointsCard
              memMissionTalkingPoints={participant.memMissionTalkingPoints}
              triggerEdit={triggerEdit}
              isSummary={isSummary}
              hasPermission={hasPermission}
            />
          </div>
        ) : (
          <></>
        )}

        {/* TODO: Add condition to show company profile based on companyProfileStatus? if needed update true to condition */}
        {true && (
          <div className='row px-3 mb-5'>
            <CompanyProfileCard
              companyProfileInfo={{
                id: participant.id,
                compLogo: participant.compLogo,
                compDisplayName: participant.compDisplayName,
                compBio: participant.compBio,
                referredName: participant.referredName,
                participationPublic: participant.participationPublic,
              }}
              triggerEdit={triggerEdit}
              isSummary={isSummary}
              hasPermission={hasPermission}
            />
          </div>
        )}

        {/* TODO: Add card for CSR display */}
        <div className='row px-3 mb-5'>
          <ActionsCard handleClose={handleClose} triggerSubmit={triggerSubmit}  triggerCancel={triggerCancel} notes={notes} setNotes={setNotes}
          isDisabled={participant.isSubmitted || participant.billingStatusEx !== 2 && participant.participantStatus !== 2 && participant.talkpointsStatus !== 2 && participant.companyProfileStatus !== 2} 
          isSummary={isSummary} 
          participantId={participant.id} hasPermission={hasPermission}/>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ParticipantModal}
