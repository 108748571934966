import { useEffect } from 'react';
import pngLogo from '../../../../assets/logo/usasean_logo.png.png'

const Login: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=018457fc-1aae-483b-a23a-8aeadccf47b1';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const apiToken = localStorage.getItem('apiToken')
  const login = () => {
    if (apiToken) {
      window.location.href = `/`
    }
    window.location.href = `${process.env.REACT_APP_BACKEND_HOST}/api/verify`
  }

  return (
    <div className='form w-100'>
      {apiToken === 'error' && (
        <div className='alert alert-danger bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10'>
          <div className='d-flex flex-column text-danger pe-0 pe-sm-10'>
            <span className=''>
              Something went wrong with connecting with the GrowthZone Platform. <br></br> <br></br>
              Please contact your administrator.
            </span>
          </div>
        </div>
      )}
      <div className='text-center w-70'>
        <div className='w-80'>
          <img src={pngLogo} className='h-350px' alt='Logo' />
        </div>
        <h1 className='text-dark fw-bolder'>Welcome</h1>
        <div className='text-gray-500 fw-semibold fs-6  d-flex justify-content-center'>
          Log in to GrowthZone to continue to Business Mission Manager
        </div>
        <button className='btn btn-bg-secondary mt-4' onClick={login}>
          Continue with GrowthZone
        </button>
        <div className='text-center mt-5'>
          <p className='text-gray-500 fw-bold fs-6'>
            Is this your first time signing in? You may need to activate your US-ASEAN Business Council online account.
            Please visit this{' '}
            <a href='https://usasean.zendesk.com/hc/en-us/articles/28298064975001-First-Time-Sign-In-Members' className='link-primary text-decoration-underline font-weight-bold'>
              First Time Sign In
            </a>
            .
          </p>
          <p className='text-gray-500 fw-bold fs-6 mt-8'>
            Need further assistance? Please email <a href='mailto:access@usasean.org' className='link-primary text-decoration-underline font-weight-bold'>access@usasean.org</a> to create a ticket.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Login
