import React, {useEffect, useState} from 'react'
import {TMission, TParticpantInfo} from '../../../../../types/types'
import {useMission} from '../../core/MissionProvider'
import {isWayPastCOB} from '../../../../../utils/helper'
import axiosInstance from '../../../../../utils/axios'
import {useParams} from 'react-router-dom'
import {convert} from 'html-to-text'

interface COBCardProps {
  memberParticipants: TParticpantInfo[]
}

const COBCard: React.FC<COBCardProps> = ({memberParticipants}) => {
  const [mission, setMission] = useState<TMission>()
  const {missionId} = useParams()
  const {currentMissionRegistration} = useMission()

  function formatDate(dateString?: undefined | string): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }
    if (!dateString) {
      return 'No date'
    } else {
      return new Date(dateString).toLocaleDateString('en-US', options)
    }
  }

  useEffect(() => {
    if (!mission) {
      const fetchMission = async () => {
        const {data} = await axiosInstance.get(`/api/missions/${missionId}`)
        setMission({
          ...data.data.attributes,
          id: data.data.id,
        })
      }

      fetchMission()
    }
  }, [mission])

  const participantLimitExceeded = memberParticipants.length > (mission?.participant ?? 0)
  const fee =
    (isWayPastCOB(mission?.COBDate,currentMissionRegistration?.createdAt) ? mission?.priceAfterCOB : mission?.priceBeforeCOB) ?? 0

  let additionalParticipantFee = 0

  if (participantLimitExceeded) {
    additionalParticipantFee =
      Math.abs(mission?.excessParticipantCost ?? 0) *
      (memberParticipants.length - (mission?.participant ?? 0))
  }

  const total = Math.abs(fee) + additionalParticipantFee

  return (
    <>
      <div className='card card-xl-stretch mb-xl-3'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-0 mb-5 mt-5 '>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-dark fs-5'>
              Registration Details <br />
              <span className=' fw-bold text-dark fs-7'>
                {' '}
                Included number of participants: {` ${mission?.participant}`}{' '}
              </span>
            </span>
            <span className='card-label fw-bold text-dark fs-5'>
              <span className=' fw-bold text-dark fs-7'>
                {' '}
                <i>
                  *Additional participants will incur a ${mission?.excessParticipantCost} fee each
                </i>
              </span>
            </span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-0 pb-5 pr-5 pl-5'>
          <div className='flex align-items-sm-center participant-container'>
            <span className='card-label fw-bold text-dark fs-5'>Registration Fee: ${fee} </span>{' '}
          </div>
          <div className='d-flex align-items-sm-center participant-container'>
            <span className='card-label fw-bold text-dark fs-5'>
              Representatives: {memberParticipants.length} / {mission?.participant}
            </span>
          </div>
          {/* begin::Item */}
          {memberParticipants.map((participant, index) => (
            <React.Fragment key={`${index}_pindex`}>
              <div className='flex-grow-1 me-2'>
                <span className='text-hover-primary fs-6 '>
                  -{' '}
                  <span
                    style={{
                      fontStyle: 'italic',
                      fontSize: '13px',
                    }}
                  >
                    {`${participant.firstName}  ${participant.lastName}`}
                    {index > (mission?.participant ?? 0) - 1 &&
                      ` (+$${mission?.excessParticipantCost})`}
                  </span>
                </span>
              </div>
            </React.Fragment>
          ))}
          {/* end::Item */}
          <div className='flex align-items-sm-center participant-container pt-5'>
            <span className='card-label fw-bold text-dark fs-5'>
              Total Fee: ${memberParticipants.length > 0 ? total : 0}
            </span>
          </div>
        </div>
        <div className='card-footer pt-5 pb-5 mb-5 pr-5 pl-5 '>
          <div className='flex align-items-sm-center mb-5 participant-container'>
            <span className='card-label fw-bold text-dark fs-7'>Fee Notes: </span>{' '}
          </div>
          <div className='d-flex align-items-sm-center participant-container'>
            <span className=' fw-bold text-dark fs-7'>
              - Fee BEFORE COB or ON COB {formatDate(mission?.COBDate)}: ${mission?.priceBeforeCOB}
            </span>
          </div>
          <div className='d-flex align-items-sm-center participant-container'>
            <span className=' fw-bold text-dark fs-7'>
              - Fee AFTER COB {formatDate(mission?.COBDate)}: ${mission?.priceAfterCOB}
            </span>
          </div>
          <div className='d-flex align-items-sm-center participant-container'>
            <span className=' fw-bold text-dark fs-7'>
              - Refund Cut-Off Date: {formatDate(mission?.refundCutDate)}
            </span>
          </div>
          <div className='d-flex align-items-sm-center participant-container'></div>
          {/* end::Item */}
          <div className='d-flex align-items-sm-start flex-column participant-container pt-2'>
            <span
              style={{
                fontSize: '12px',
                fontStyle: 'italic',
              }}
            >
              - Refunds must be requested by <strong> {formatDate(mission?.refundCutDate)} </strong>{' '}
              in order to be processed.
            </span>
            {currentMissionRegistration?.notes && (
              <span className='card-label fw-bold text-dark fs-7 mt-10'>
                Additional Notes:{' '}
                <span
                  style={{
                    fontSize: '12px',
                    fontStyle: 'italic',
                    fontWeight: '400',
                  }}
                >
                  {convert(currentMissionRegistration.notes)}
                </span>
              </span>
            )}
          </div>
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export default COBCard
